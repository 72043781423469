import { request } from '../network/request.js'

export function pkInoutLog(query, head) {
  // 进出记录查询
  return request({
    url: '/parking/saas/pkInoutLog',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function pkInoutData(query, head) {
  // 停车记录查询
  return request({
    url: '/parking/saas/pkInoutData',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function orderInfo(query, head) {
  // 订单记录查询
  return request({
    url: '/parking/saas/orderInfo',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function invoiceRecord(query, head) {
  // PC 查询开票记录
  return request({
    url: '/parking/saas/orderInfoInvoice/list',
    method: 'post',
    headers: head,
    data: query,
  })
}

export function abnormalOrderinfo(query, head) {
  // 异常记录查询
  return request({
    url: '/parking/saas/orderInfo/abnormalOrderinfo',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function delpkInoutLog(id, head) {
  // 进出记录删除
  return request({
    url: '/parking/saas/pkInoutLog/' + id,
    method: 'delete',
    headers: head,
  })
}

export function pkDoor(head) {
  // 通道查询
  return request({
    url: '/parking/saas/pkDoor',
    method: 'get',
    headers: head,
  })
}

export function delpkInoutData(id, head) {
  // 停车记录删除
  return request({
    url: '/parking/saas/pkInoutData/' + id,
    method: 'delete',
    headers: head,
  })
}

export function selectByInoutDatatk(query, head) {
  // 停车记录查询弹框
  return request({
    url: '/parking/saas/orderInfo/selectByInoutData',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function selectByPayLogtk(query, head) {
  // 支付日志查询弹框
  return request({
    url: '/parking/saas/orderInfo/selectByPayLog',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function selectOrderInfotk(query, head) {
  // 订单详情查询
  return request({
    url: '/parking/saas/orderInfo/selectOrderInfo',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function manualInvoice(query, head) {
  // 手动开票
  return request({
    url: '/parking/saas/invoice/billingManual',
    method: 'post',
    headers: head,
    data: query,
  })
}

export function findByInoutdataId(query, head) {
  // 根据订车记录ID查询订单记录
  return request({
    url: '/parking/saas/pkInoutLog/findByInoutdataId',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function pkInoutLogexcel(query, head) {
  // 进出记录excel导出
  return request({
    url: '/parking/saas/pkInoutLog/excel',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function pkInoutDataexcel(query, head) {
  // 停车记录excel导出
  return request({
    url: '/parking/saas/pkInoutData/excel',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function orderInfoexcel(query, head) {
  // 订单记录excel导出
  return request({
    url: '/parking/saas/orderInfo/excel',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function findPkCarLog(query, head) {
  // 查询停车日志
  return request({
    url: '/parking/saas/pkInoutLog/findPkCarLog',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function findPayFailSumMoney(query, head) {
  // 失败订单总金额查询
  return request({
    url: '/parking/saas/orderInfo/findPayFailSumMoney',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function newOrderInfo(query, head) {
  // 新订单记录
  return request({
    url: '/parking/saas/orderInfo/newOrderInfo',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function orderQueryDataBill(query, head) {
  // 新订单记录总金额
  return request({
    url: '/parking/saas/orderInfo/orderInfoBill',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function orderInfoDetailBill(query, head) {
  // 区域订单记录总金额
  return request({
    url: '/parking/saas/orderInfo/orderInfoDetailBill',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function orderInfoDetail(query, head) {
  // 区域订单记录查询
  return request({
    url: '/parking/saas/orderInfo/orderInfoDetail',
    method: 'get',
    headers: head,
    params: query,
  })
}
export function exportOrderinfoDetail(query, head) {
  // 区域订单记录excel导出
  return request({
    url: '/parking/saas/orderInfo/exportOrderinfoDetail',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function userGroupTransfer(query, head) {
  // 根据停车场查询用户分组
  return request({
    url: '/parking/saas/userGroupTransfer',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function exportOrderQuertData(query, head) {
  // 新订单记录excel导出
  return request({
    url: '/parking/saas/orderInfo/exportOrderInfoData',
    method: 'get',
    headers: head,
    params: query,
  })
}
export function exportAbnormalOrderinfo(query, head) {
  // 异常订单excel导出
  return request({
    url: '/parking/saas/orderInfo/exportAbnormalOrderinfo',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function listByOrderId(query, head) {
  // PC 查询开票记录 根据orderId
  return request({
    url: '/parking/saas/orderInfoInvoice/listByOrderId',
    method: 'get',
    headers: head,
    params: query,
  })
}

export function regainStatus(query, head) {
  // PC端重新开票
  return request({
    url: '/parking/saas/orderInfoInvoice/allowReBilling',
    method: 'post',
    headers: head,
    params: query,
  })
}


export function orderPyaLogRedress(head) {
  // 纠正跨天订单
  return request({
    url: '/parking/saas/orderPayLog/orderPyaLogRedress',
    method: 'get',
    headers: head,
  })
}